document.addEventListener("DOMContentLoaded", function () {



    //timeline
    let sections = gsap.utils.toArray(".panel");
    //let mmTimeline = gsap.matchMedia(); // responsive approach

   // mmTimeline.add("(min-width: 1024px)", () => {
                gsap.to(sections, {
                xPercent: -108 * (sections.length - 1),
                // xPercent: +100 * (sections.length - 1), //for arabic
                ease: "none", // <-- IMPORTANT!
                    scrollTrigger: {
                    trigger: "#timeline",
                    start: "clamp(top top)",
                    end: "clamp(bottom center)",
                    pin: "#ipo-sub",
                    //markers: true,
                    id:"timeline",
                    scrub: true
                }
                });
    //});

})